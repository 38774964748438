<template>
	<section class="bg-green section-2">
		<div class="container py-5">
			<div class="row">
				<div class="col-12">
					<h2 class="h1 mt-5 col-md-8"> Contactez-nous pour Plus d'Informations&thinsp;! </h2>
					<p class="p-important col-md-8"> 
						Vous avez des questions ou besoin de conseils ? N'hésitez pas à nous appeler directement pour discuter de la façon dont PAWPAW peut vous aider, quelles que soient les difficultés rencontrées par votre chien. 
						<br><br>
						Appelez-nous pour découvrir ensemble des solutions personnalisées.
					</p>
					<p class="p-important mt-2 mb-3">
                        <a class="p-important text-white text-decoration-none" href="tel:0032479578949"><span class="call-to-action-phone"><i class="bi bi-telephone"></i>&nbsp;&nbsp;</span><span class="call-to-action-phone"><b>+32 479 57 89 49</b></span></a>
                    </p>
				</div>
			</div>
			<div class="row my-5 d-none d-md-block"></div>
		</div>
	</section>
</template>
<script>
	export default {

	}
</script>