<template>
	<section class="bg-pink section-3">
		<div class="container min-vh-100 py-5">
			<div class="row">
				<div class="col-12">
					<h2 class="h1 mt-5 col-md-8">La méthode PAWPAW</h2>
					<div class="col-12"></div>
					<p class="p-important col-md-8"> Bienvenue dans l'univers de la méthode PAWPAW, où l'innovation et l'originalité redéfinissent l'éducation canine. Notre philosophie s'ancre dans une compréhension profonde des besoins et du comportement canin, visant à établir
						une relation harmonieuse et respectueuse entre vous et votre compagnon. </p>
					<!-- <h3 class="mt-5 h1">Nos Trois Piliers&thinsp;:</h3> -->
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-12">
					<h2 class="h1 mt-5">Nos services incluent&thinsp;:</h2>
				</div>
				<div class="col-12 my-5"></div>
				<div class="col-md-4">
					<div class="block-green block-pink px-4 py-5 rounded">
						<h3 class="p-important">Séances d'entraînement personnalisées</h3>
						<p> Chez PAWPAW, chaque chien est unique. Nos séances d'entraînement personnalisées sont conçues pour répondre aux besoins spécifiques de votre compagnon. Nous couvrons des compétences essentielles telles que : </p>
						<ul>
							<li><b>Assis, Couché, Debout :</b> Des instructions de base pour améliorer l'écoute de votre chien. </li>
							<li><b>Stop :</b> Utilisé pour garantir la sécurité et le contrôle dans différentes situations. </li>
							<li><b>Marche en laisse :</b> Pour des promenades détendues et agréables. </li>
							<li><b>Pas bougé :</b> Pour cultiver la patience et la gestion de l'impulsivité chez votre chien. </li>
							<li><b>Rappel :</b> Pour assurer une réponse fiable de votre chien en toutes circonstances. </li>
						</ul>
					</div>
				</div>
				<div class="col-md-4">
					<div class="block-green block-pink px-4 py-5 rounded block-green-second">
						<h3 class="p-important"> Solutions pour divers troubles du comportement </h3>
						<p> Nous abordons une gamme de troubles comportementaux, en offrant des stratégies personnalisées pour chaque cas : </p>
						<ul>
							<li><b>Agressivité :</b> Vers une cohabitation pacifique avec les humains et autres animaux. </li>
							<li><b>Anxiété de séparation :</b> Aider votre chien à se sentir en sécurité même en votre absence. </li>
							<li><b>Crainte :</b> Renforcer la confiance et la sécurité.</li>
							<li><b>Troubles de l'attention et de la concentration (HS / HA) :</b> Améliorer la focalisation de votre chien. </li>
							<li><b>Malpropreté :</b> Des solutions pour une hygiène irréprochable. </li>
							<li><b>Aboiements excessifs :</b> Réduire les nuisances sonores. </li>
							<li><b>Destructions, Fugues, Sauts, Vols :</b> Gérer les comportements destructeurs et inappropriés. </li>
							<li><b>Sur-socialisation :</b> Apprendre à votre chien à rester calme et concentré, même en présence d'autres chiens, et éviter les interactions systématiques.	</li>
						</ul>
					</div>
				</div>
				<div class="col-md-4">
					<div class="block-green block-pink px-4 py-5 rounded">
						<h3 class="p-important">L'ABC des chiots</h3>
						<p> Avec notre approche "L'ABC des chiots", nous vous accompagnons pour les premières étapes essentielles : apprentissage de la propreté, gestion des peurs, mise en place des règles de vie et développement de la relation maître-chiot. Tout est conçu pour que votre chiot soit serein et prêt à rencontrer ses congénères plus tard.</p>
					</div>
				</div>
				<div class="col-12 my-5 section-2-r2"></div>
				<div class="col-md-4">
					<div class="block-green block-pink px-4 py-5 rounded ">
						<h3 class="p-important">Cours Collectifs</h3>
						<p> Nos cours collectifs sont bien plus que de simples séances d'entraînement en groupe : ce sont de véritables promenades éducatives. En changeant régulièrement de lieux, nous offrons à votre chien la possibilité de s'adapter à des environnements variés et de mettre en pratique ses apprentissages dans des situations réelles. C'est une excellente opportunité pour la socialisation, l'exercice physique et le renforcement des comportements appris, tout en profitant de la nature et de moments de partage avec d'autres chiens.</p>
					</div>
				</div>
				<div class="col-md-4">
					<div class="block-green block-pink px-4 py-5 rounded block-green-second">
						<h3 class="p-important">Documentation claire & Disponibilité pour vos questions</h3>
						<p> Chez PAWPAW, nous croyons que l’apprentissage ne s’arrête pas à la fin d’une séance. C’est pourquoi nous mettons à votre disposition une documentation claire et complète pour vous accompagner dans chaque étape de l’éducation de votre chien. Ces ressources vous permettent de revoir les exercices et les conseils à tout moment. De plus, nous restons disponibles pour répondre à vos questions en dehors des cours, afin de vous apporter le soutien nécessaire pour surmonter les petits défis du quotidien avec votre compagnon.</p>
					</div>
				</div>
				<div class="col-12 mb-5"></div>
			</div>
		</div>
	</section>
</template>
<script>
	export default {

	}
</script>