<template>
  <Header />
  <Home />
  <DefisCourants />
  <NosServices />
  <!-- <FonctionnementPawpaw /> -->
  <APropos />
  <TemoignagesSatisfaction />
  <AppelDecouverte />
  <FooterPawpaw />
</template>

<script>
import Header from './components/headerTemplate'
import Home from './components/HomeSection1'
import DefisCourants from './components/DefisCourants'
import NosServices from './components/NosServices'
//import FonctionnementPawpaw from './components/FonctionnementPawpaw'
import APropos from './components/APropos'
import TemoignagesSatisfaction from './components/TemoignagesSatisfaction'
import AppelDecouverte from './components/AppelDecouverte'
import FooterPawpaw from './components/FooterPawpaw'



export default {
  name: 'App',
  components: {
    Header,
    Home,
    DefisCourants,
    NosServices,
    //FonctionnementPawpaw,
    APropos,
    TemoignagesSatisfaction,
    AppelDecouverte,
    FooterPawpaw
  }
}
</script>

<style lang="scss">
@import url(https://use.typekit.net/mas3fbm.css?v=2);
@import "@/assets/index.scss";

/* Autres styles spécifiques à App.vue */
</style>
