<template>
	<section>
		<div class="container">
			<div class="row py-5">
				<div class="col-md-6 d-flex flex-column justify-content-center align-items-center"><a target="_blank" href="https://www.google.be/search?sca_esv=3acf9be9fedf7d41&amp;tbm=lcl&amp;sxsrf=ACQVn08LE_JJ06218z0lt54_MLnItLtFNw:1712589762033&amp;q=PAWPAW+Avis&amp;rflfq=1&amp;num=20&amp;stick=H4sIAAAAAAAAAONgkxIxNDIzMjUyNzc0NrI0MLc0NDIyNNrAyPiKkTvAMRyIFBzLMosXsSLzAM8HgpM3AAAA&amp;rldimm=12625277132907912212&amp;hl=fr-BE&amp;sa=X&amp;ved=2ahUKEwitt6WD9rKFAxU_VaQEHfNbA-gQ9fQKegQIMxAF&amp;biw=1866&amp;bih=948&amp;dpr=1#lkt=LocalPoiReviews"><img src="@/img/score.png"></a>
					<h2
						class="h1 my-5" style="color:#654897;text-align:center;"> Témoignages <br> de Satisfaction </h2>
				</div>
				<div class="col-md-6 d-flex align-items-center"><a target="_blank" href="https://www.google.be/search?sca_esv=3acf9be9fedf7d41&amp;tbm=lcl&amp;sxsrf=ACQVn08LE_JJ06218z0lt54_MLnItLtFNw:1712589762033&amp;q=PAWPAW+Avis&amp;rflfq=1&amp;num=20&amp;stick=H4sIAAAAAAAAAONgkxIxNDIzMjUyNzc0NrI0MLc0NDIyNNrAyPiKkTvAMRyIFBzLMosXsSLzAM8HgpM3AAAA&amp;rldimm=12625277132907912212&amp;hl=fr-BE&amp;sa=X&amp;ved=2ahUKEwitt6WD9rKFAxU_VaQEHfNbA-gQ9fQKegQIMxAF&amp;biw=1866&amp;bih=948&amp;dpr=1#lkt=LocalPoiReviews"><img style="max-width:100%;" src="@/img/latest.png"></a></div>
			</div>
		</div>
	</section>
</template>
<script>
	export default {

	}
</script>