<template>
	<section class="bg-purple section-1">
		<div class="container min-vh-100">
			<div class="row min-vh-100">
				<div class="col-md-6 d-flex align-items-center intro-text">
					<div>
						<h1>Votre Éducateur Comportementaliste Canin basé à Bruxelles.</h1>
						<p class="p-important"> Education, comportement &amp; résolution de troubles pour toutes races et tous âges. </p>
						<p class="p-important mt-5 mb-3">
                            <a class="p-important text-white text-decoration-none" href="tel:0032479578949"><span class="call-to-action-phone"><i class="bi bi-telephone"></i>&nbsp;&nbsp;Contactez-nous</span><span class="call-to-action-phone"> au <b>+32 479 57 89 49</b></span></a>
                        </p>
						<p
							class="d-flex my-4" style="margin-left:-0.5rem !important;"><a target="_blank" href="https://www.instagram.com/PAWPAW_education_canine/" class="btn btn-primary btn-mail icon-button rounded-circle mx-2"><i class="bi bi-instagram"></i></a><a target="_blank" href="https://www.facebook.com/PAWPAWdogacademy/"
								class="btn btn-primary btn-mail icon-button rounded-circle mx-2"><i class="bi bi-facebook"></i></a><a target="_blank" href="https://www.tiktok.com/@PAWPAWacademy" class="btn btn-primary btn-mail icon-button rounded-circle mx-2"><i class="bi bi-tiktok"></i></a>
							<a
								target="_blank" href="https://www.youtube.com/@PAWPAWAcademy" class="btn btn-primary btn-mail icon-button rounded-circle mx-2"><i class="bi bi-youtube"></i></a>
						</p>
					</div>
				</div>
				<div class="col-md-6 d-flex align-items-end"><img class="dog" src="@/img/dog.png"></div>
			</div>
		</div>
	</section>
</template>