<template>
	<section class="bg-purple section-5">
		<div class="container min-vh-100">
			<div class="row min-vh-100">
				<div class="col-md-6 d-flex align-items-center">
					<div class="py-5">
						<h2 class="h1 mt-5">PAWPAW Education & Comportement Canin</h2>
						<p class="p-important mb-5"> Nous sommes là pour vous aider à construire une relation harmonieuse et épanouie avec votre chien. </p>
						<!-- <p> Pour discuter de vos besoins et planifier une consultation : <br><a class="btn-tel" href="tel:0032479578949">Tel. +32 479 57 89 49</a></p> -->
						<ul class="text-light-purple list-unstyled">
							<li><a href="https://www.instagram.com/PAWPAW_education_canine/">Instagram</a></li>
							<li><a href="https://www.facebook.com/PAWPAWdogacademy/">Facebook</a></li>
							<li><a href="https://www.tiktok.com/@PAWPAWacademy">TikTok</a></li>
							<li><a href="https://www.youtube.com/@PAWPAWAcademy">Youtube</a></li>
						</ul><address class="text-light-purple"><a href="https://www.google.com/maps/place/PAWPAW/@50.8050474,4.3366032,15z/data=!4m6!3m5!1s0x47c3c5c3c7cbb0d5:0xaf35fea929d69c14!8m2!3d50.8050474!4d4.3366032!16s%2Fg%2F11vjjl0nms?hl=fr&amp;entry=ttu" target="_blank">Chaussée d'Alsemberg 711, 1180 Uccle</a></address>
						<p
							class="text-light-purple"> Par: Odoacer Group SRL <br>TVA: BE 0 541 980 669 </p>
							<div class="my-5"><img class="logo-slogan" src="@/img/logo-slogan.png" alt="Logo"></div>
					</div>
				</div>
				<div class="col-md-6 d-flex align-items-end"><img class="dog" src="@/img/dog.png"></div>
			</div>
		</div>
	</section>
</template>
<script>
	export default {

	}
</script>