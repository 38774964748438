<template>
	<section class="bg-orange section-4">
		<div class="container min-vh-100">
			<div class="row min-vh-100 py-5">
				<div class="col-md-6 d-flex align-items-center"><img class="black-et-jerome mt-5" src="@/img/Black-et-Jerome-square.jpg"></div>
				<div class="col-md-6 d-flex align-items-center">
					<div>
						<h2 class="h1 my-5">À propos de nous</h2>
						<p class="p-important"> Voici Black, mon fidèle Golden Retriever, et je suis Jérôme Obbiet, fondateur de PAWPAW. Notre histoire dépasse la simple amitié ; elle a été le catalyseur d'un profond changement de vie et la raison pour laquelle j'ai embrassé la carrière d'éducateur canin.</p>
						<p class="p-important mb-5">
						Au départ, je me suis trouvé insatisfait des méthodes conventionnelles d'éducation canine, souvent trop rigides et limitatives. Motivé par cette frustration et inspiré par l'intelligence et l'affection de Black, j'ai décidé de poursuivre une formation spécialisée. Cette formation a été le point de départ pour affiner et adapter ma méthode, enrichie continuellement par mon expérience sur le terrain. Ce parcours m'a permis d'explorer différentes écoles de pensée dans le domaine de l'éducation canine et de développer une méthode flexible, conçue pour s'adapter aux besoins spécifiques de chaque chien et de son maître.
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	export default {

	}
</script>