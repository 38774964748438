<template>
	<section class="bg-green section-2">
		<div class="container min-vh-100 py-5">
			<div class="row">
				<div class="col-12">
					<h2 class="h1 mt-5 col-md-8"> Rencontrez-vous ces défis avec votre compagnon à quatre pattes&thinsp;? </h2>
				</div>
			</div>
			<div class="row my-5"></div>
			<div class="row my-5 section-2-r1">
				<div class="col-md-4">
					<div class="block-green px-4 py-5 rounded">
						<h3>Tirer en laisse</h3>
						<p> Votre promenade quotidienne est-elle devenue une lutte de pouvoir&thinsp;? Nous pouvons transformer ces moments de tension en balades agréables et détendues. </p>
					</div>
				</div>
				<div class="col-md-4">
					<div class="block-green px-4 py-5 rounded block-green-second">
						<h3>Destruction à la maison</h3>
						<p> Votre chien laisse-t-il un sillage de destruction quand vous n'êtes pas là&thinsp;? Comprendre et résoudre ce comportement est à portée de main. </p>
					</div>
				</div>
				<div class="col-md-4">
					<div class="block-green px-4 py-5 rounded">
						<h3>Aboiements excessifs</h3>
						<p> Les aboiements constants perturbent votre quotidien&thinsp;? Apprenez à communiquer efficacement avec votre chien pour réduire le bruit. </p>
					</div>
				</div>
			</div>
			<div class="row mt-5 section-2-r2"></div>
			<div class="row my-5 justify-content-center section-2-r3">
				<div class="col-md-4">
					<div class="block-green px-4 py-5 rounded">
						<h3>Problèmes d'agressivité ou de peur</h3>
						<p> Votre chien montre-t-il des signes d'agressivité ou de peur envers les humains ou d'autres animaux&thinsp;? Nous pouvons vous aider à instaurer un climat de confiance et de sécurité. </p>
					</div>
				</div>
				<div class="col-md-4">
					<div class="block-green px-4 py-5 rounded block-green-second">
						<h3>Difficultés d'écoute</h3>
						<p>Les instructions de base semblent-elles être ignorées&thinsp;? Nous travaillons sur l'écoute pour renforcer la compréhension mutuelle entre vous et votre chien.</p>
					</div>
				</div>
			</div>
			<div class="row my-5 d-none d-md-block"></div>
		</div>
	</section>
</template>
<script>
	export default {

	}
</script>