<template>
	<header class="header-menu d-flex justify-content-between p-5">
		<div><img class="logo" src="@/img/pawpaw_logo.svg" alt="Pawpaw"></div>
		<div class="d-flex">
			<div class="d-none d-md-block"><a class="btn-tel" href="tel:0032479578949">Tel. +32 479 57 89 49</a></div>
		</div>
		<div class="d-flex d-sm-flex d-md-none"><a href="tel:0032479578949" class="btn btn-primary btn-mail rounded-circle icon-button"><i class="bi bi-telephone"></i></a></div>
	</header>
</template>

<script>
	export default {

	}
</script>